import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import {
  getFileExtension,
  getFileName,
  getIconForKey,
} from "../../utils/commonUtils";

const FileChip = ({ fileName, link }) => {
  const theme = useTheme();
  const styles = {
    chip: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      padding: theme.spacing(0.5, 1),
      border: `1px solid ${theme.palette.surface20.main}`,
      borderRadius: "24px",
      maxWidth: "100%",
      width: "fit-content",
    },
    gotoLink: {
      cursor: "pointer",
    },
    truncateText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  };
  return fileName ? (
    <Box sx={styles.chip}>
      <img
        src={getIconForKey("file", getFileExtension(fileName))}
        alt="file"
        height={16}
      />
      <Typography
        variant="caption"
        color={theme.palette.surface80.main}
        sx={styles.truncateText}
        title={fileName}
      >
        {getFileName(fileName)}
      </Typography>
      {/*<OpenInNewRoundedIcon
        sx={styles.gotoLink}
        onClick={() => window.open(link, "_blank")}
        htmlColor={theme.palette.surface40.main}
        fontSize="small"
      />*/}
    </Box>
  ) : null;
};

export default FileChip;
