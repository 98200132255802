import { useTheme } from "@mui/material";
import CustomTable from "../common/CustomTable";
import { useEffect, useMemo, useState } from "react";
import { axiosPost } from "../../services/apiClient";
import {
  formatBytes,
  getFileName,
  getFirstNValue,
  getIconForKey,
} from "../../utils/commonUtils";
import Box from "../common/Box";
import Typography from "../common/Typography";
import ColorBadge from "../common/ColorBadge";
import Tooltip from "../common/Tooltip";
import Chip from "../common/Chip";
import CardWithAction from "./CardWithAction";
import LogoWrapper from "../common/LogoWrapper";
import { useDispatch } from "react-redux";
import { fetchImpactedDocumentsSlice } from "../../redux/slices/documentSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { useHistory } from "react-router-dom";
import { TagColorMapping } from "../constants/constants";
import { AppDispatch } from "../../redux/store";

const SHOW_DOCUMENTS_COUNT = 5;

const DocumentCard = () => {
  const [impactedDocs, setImpactedDocs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const theme = useTheme();
  const styles = {
    docName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    name: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    snippetCol: {
      gap: theme.spacing(0.5),
    },
    snippet: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    groups: {
      alignItems: "center",
    },
    manrope: {
      fontFamily: "Manrope",
    },
    snippetCount: {
      fontSize: "20px",
      color: theme.palette.surface90.main,
      fontWeight: "500",
    },
    groupCount: {
      fontSize: "20px",
      color: theme.palette.surface60.main,
    },
    truncateText: {
      whiteSpace: "nowrap",
      maxWidth: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const tableCols = [
    {
      id: 1,
      title: "Name",
      render: (data) => (
        <Box sx={styles.docName}>
          <LogoWrapper
            src={getIconForKey("data_loader", data?.loader_type)}
            alt="source"
            height="20px"
            bgColor="#2E3351"
          />
          <Box sx={styles.name}>
            <Tooltip title={data?.name}>
              <div>
                <Typography
                  variant="body2"
                  color={theme.palette.surface60.main}
                  sx={styles.truncateText}
                >
                  {getFileName(data?.name)}
                </Typography>
              </div>
            </Tooltip>
            <Typography variant="tooltip" color={theme.palette.surface50.main}>
              {formatBytes(data?.doc_size) || "-"}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: 2,
      title: "Snippets with Impact",
      render: (data) => {
        const count =
          data?.snippet_count?.sensitive?.count ||
          data?.snippet_count?.harmful?.count;
        const tag =
          data?.snippet_count?.sensitive?.count > 0
            ? "Sensitive"
            : data?.snippet_count?.hamrful?.count > 0
            ? "Harmful"
            : "";
        const labels =
          data?.snippet_count?.sensitive?.labels ||
          data?.snippet_count?.harmful?.labels;
        const color = TagColorMapping[tag];

        return (
          <Box sx={{ ...styles.name, ...styles.snippetCol }}>
            <Box sx={styles.snippet}>
              <Typography sx={{ ...styles.manrope, ...styles.snippetCount }}>
                {count}
              </Typography>
              <Typography variant="body2" color={theme.palette.surface60.main}>
                {tag}
              </Typography>
              {color && <ColorBadge color={color} />}
            </Box>
            {labels?.length > 0 ? (
              <Tooltip
                title={labels?.join(", ")}
                children={
                  <div>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface50.main}
                    >
                      {getFirstNValue(labels, 1)}
                    </Typography>
                  </div>
                }
              />
            ) : null}
          </Box>
        );
      },
    },
    {
      id: 3,
      title: "Groups with Access",
      render: (data) => {
        return (
          <Box sx={{ ...styles.name, ...styles.snippetCol }}>
            <Box sx={{ ...styles.snippet, ...styles.groups }}>
              <Typography sx={{ ...styles.groupCount, ...styles.manrope }}>
                {data?.access_groups?.length || 0}
              </Typography>
              {/*{data?.overprovisioned ? <Chip label="Over-Provisioned" /> : ""}*/}
            </Box>
            {data?.access_groups?.length ? (
              <Tooltip
                title={data?.access_groups?.join(", ")}
                children={
                  <div>
                    <Typography
                      variant="caption"
                      color={theme.palette.surface50.main}
                    >
                      {getFirstNValue(data?.access_groups, 1)}
                    </Typography>
                  </div>
                }
              />
            ) : null}
          </Box>
        );
      },
    },
  ];

  const gotoDocument = async (doc: { id: string[] }) => {
    const docId = doc?.id?.length ? doc?.id[0] : "";
    if (docId) history.push(`/documents/${docId}`);
  };

  const cardData = useMemo(
    () => ({
      "impacted-docs": {
        title: "Most Impacted Documents",
        children: (
          <CustomTable
            onRowClick={gotoDocument}
            tableCols={tableCols}
            tableData={impactedDocs}
            tableHeight="500px"
          />
        ),
      },
    }),
    [impactedDocs]
  );

  const fetchImpactedDocs = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      count: SHOW_DOCUMENTS_COUNT,
    };
    const response = await dispatch(fetchImpactedDocumentsSlice(payload));
    if (response?.payload) {
      const docList = Object.keys(response?.payload?.data).map((key) => ({
        name: key,
        ...response?.payload?.data[key],
      }));
      setImpactedDocs(docList);
    } else {
      setImpactedDocs([]);
    }
    setIsLoading(false);
  };

  const gotoAllDocuments = async () => {
    history.push("/documents");
  };

  useEffect(() => {
    fetchImpactedDocs();
  }, []);

  return (
    <CardWithAction
      cardData={cardData}
      buttonTitle="View All"
      onClick={gotoAllDocuments}
      isLoading={isLoading}
    />
  );
};

export default DocumentCard;
