import { Grid, useTheme } from "@mui/material";

import Box from "../common/Box";

import Typography from "../common/Typography";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import Loader from "../common/Loader";
import CardWithAction from "./CardWithAction";
import { getIconForKey } from "../../utils/commonUtils";
import { capitalizeFirstLetter } from "../../utils/utils";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchLLMModelsListingSlice } from "../../redux/slices/dashboardSlice";
import NoData from "../common/NoData";
import { AppDispatch } from "../../redux/store";

const LLMCard = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [models, setModels] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const styles = {
    listing: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: "400px",
      overflowY: "auto",
    },
    card: {
      display: "flex",
      gap: theme.spacing(1),
      paddingY: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      backgroundColor: "rgba(49, 53, 74, 0.5)",
      borderRadius: "4px",
    },
    details: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: theme.spacing(2),
    },
    keyValue: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const getModelsData = async (payload: any) => {
    setIsLoading(true);
    const res = await dispatch(fetchLLMModelsListingSlice(payload));
    if (res.payload) {
      setModels(res.payload?.data);
    } else {
      setModels([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const payload = {
      customerId: getCustomerId(),
    };
    getModelsData(payload);
  }, []);

  const cardData = useMemo(
    () => ({
      llms: {
        title: "LLMs",
        children: (
          <Box sx={styles.listing}>
            {!isLoading ? (
              models?.length > 0 ? (
                models.map((model: any) => {
                  return (
                    <Box sx={styles.card}>
                      <img
                        src={getIconForKey("llm", model?.name)}
                        alt="source"
                        height={16}
                      />
                      <Box sx={styles.keyValue}>
                        <Typography
                          variant="body2"
                          color={theme.palette.surface60.main}
                        >
                          {model?.name}
                        </Typography>
                        <Box sx={styles.details}>
                          <Box sx={styles.keyValue}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface30.main}
                            >
                              Status
                            </Typography>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {model?.status || "-"}
                            </Typography>
                          </Box>
                          <Box sx={styles.keyValue}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface30.main}
                            >
                              Category
                            </Typography>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {capitalizeFirstLetter(model?.datastoreType)}
                            </Typography>
                          </Box>
                          <Box sx={styles.keyValue}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface30.main}
                            >
                              Labels
                            </Typography>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {model?.labels || "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <NoData customText="No Data Found" />
              )
            ) : (
              <Loader />
            )}
          </Box>
        ),
      },
    }),
    [models, isLoading]
  );

  return <CardWithAction cardData={cardData} />;
};

export default LLMCard;
