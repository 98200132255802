import Box from "../../../components/common/Box";
import Divider from "../../../components/common/Divider";
import TextButton from "../../../components/common/TextButton";
import TitlePath from "../../../components/common/TooltipTruncatedText";
import Typography from "../../../components/common/Typography";
import JiraIcon from "../../../assets/img/jira.svg";
import GroupsIcon from "../../../assets/img/users.svg";
import { useTheme } from "@mui/material";
import {
  formatBytes,
  getFileExtension,
  getFileName,
  getIconForKey,
} from "../../../utils/commonUtils";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useEffect, useState } from "react";
import LabelAndValue from "../../../components/common/LabelAndValue";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDocumentInfoSlice } from "../../../redux/slices/documentSlice";
import Tooltip from "../../../components/common/Tooltip";
import Loader from "../../../components/common/Loader";
import { AppDispatch } from "../../../redux/store";
import UserGroupsListing from "../../../components/custom/UserGroupsListing";

const DocumentInfo = ({ name }) => {
  const [document, setDocument] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();

  const styles = {
    docTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "start",
      width: "30%",
    },
    docDetails: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(5),
      backgroundColor: theme.palette.surface5.main,
    },
    cardFirstRow: {
      width: "100%",
      //display: "grid",
      //gridTemplateColumns: "40% 25% 25% 10%",
      display: "flex",
      justifyContent: "space-between",
    },
    labelSubtext: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      width: "fit-content",
      maxWidth: "100%",
    },
    divider: {
      backgroundColor: theme.palette.surface20.main,
    },
    jiraBtn: {
      //marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      paddingY: theme.spacing(1),
      color: theme.palette.surface60.main,
      cursor: "pointer",
    },
    docGroupsInfo: {
      display: "grid",
      gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
      justifyItems: "center",
      gridGap: theme.spacing(2),
    },
    font13: {
      fontSize: "13px",
    },
    group: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    groupCount: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "24px",
    },
    groupFirstChild: {
      justifySelf: "flex-start",
    },
    groupList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    row: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    labelGraph: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    docFullName: {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const getDocumentInfo = async () => {
    setIsLoading(true);
    const documentId = params?.doc;

    const payload = {
      documentName: name,
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchDocumentInfoSlice(payload));
    if (response?.payload?.data) {
      const doc = response.payload.data;
      setDocument({
        ...doc,
        harmful_labels: Object.keys(doc?.harmful_labels)?.map((label) => ({
          name: label,
          count: doc?.harmful_labels[label],
        })),
        sensitive_labels: Object.keys(doc?.sensitive_labels)?.map((label) => ({
          name: label,
          count: doc?.sensitive_labels[label],
        })),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (name) getDocumentInfo();
  }, [name]);

  return (
    <Box sx={styles.docDetails}>
      {isLoading ? (
        <Loader height="200px" />
      ) : (
        <>
          <Box sx={styles.cardFirstRow}>
            <Box sx={styles.docTitle}>
              <img
                src={getIconForKey("file", getFileExtension(document?.name))}
                alt="type"
                height={20}
              />
              <Box sx={styles.labelSubtext}>
                <Tooltip title={document?.name}>
                  <div>
                    <Typography
                      color={theme.palette.surface80.main}
                      sx={styles.docFullName}
                      variant="h6"
                    >
                      {getFileName(document?.name)}
                    </Typography>
                  </div>
                </Tooltip>
                <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}
                >
                  {formatBytes(document?.doc_size)}
                </Typography>
              </Box>
            </Box>
            <>
              {document?.loader_type ? (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={styles.divider}
                  />
                  <TitlePath
                    type="data_loader"
                    entityKey={document?.loader_type}
                    subText={document?.name}
                  />
                </>
              ) : null}
            </>
            <>
              {document?.vector_db ? (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={styles.divider}
                  />
                  <TitlePath
                    type="vector_db"
                    entityKey={document?.vector_db}
                    subText={`Last Refreshed : ${
                      document?.database?.updatedAt || "-"
                    }`}
                  />
                </>
              ) : null}
            </>
            {/*<TextButton
              startIcon={<img src={JiraIcon} alt="jira" />}
              sx={styles.jiraBtn}
              disabled={true}
            >
              Create Ticket
            </TextButton>*/}
          </Box>
          <Box sx={styles.docGroupsInfo}>
            <Box sx={styles.groupFirstChild}>
              <UserGroupsListing
                groups={document?.groups}
                title="Groups with Access"
              />
            </Box>
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Box sx={styles.group}>
              <Box sx={styles.groupTitle}>
                <Typography
                  sx={styles.groupCount}
                  color={theme.palette.surface80.main}
                >
                  {document?.harmful_labels?.length}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.surface80.main}
                >
                  Harmful Labels
                </Typography>
              </Box>
              <Box sx={styles.labelGraph}>
                <LabelAndValue labels={document?.harmful_labels} />
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Box sx={styles.group}>
              <Box sx={styles.groupTitle}>
                <Typography
                  sx={styles.groupCount}
                  color={theme.palette.surface80.main}
                >
                  {document?.sensitive_labels?.length}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.surface80.main}
                >
                  Sensitive Labels
                </Typography>
              </Box>
              <Box sx={styles.labelGraph}>
                <LabelAndValue labels={document?.sensitive_labels} />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DocumentInfo;
