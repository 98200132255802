import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Tooltip from "../../components/common/Tooltip";
import { getIconForKey } from "../../utils/commonUtils";

const TitlePath = ({ type, entityKey, subText }) => {
  const theme = useTheme();
  const styles = {
    block: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "start",
    },
    nameSubtext: {
      display: "flex",
      flexDirection: "column",
    },
    truncateText: {
      width: "300px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };

  return (
    <Box sx={styles.block}>
      {entityKey && (
        <img src={getIconForKey(type, entityKey)} alt="source" height={22} />
      )}
      <Box sx={styles.nameSubtext}>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          {entityKey}
        </Typography>
        <Tooltip
          title={subText}
          children={
            <div style={styles.truncateText as any}>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
                sx={styles.truncateText}
              >
                {subText}
              </Typography>
            </div>
          }
        />
      </Box>
    </Box>
  );
};

export default TitlePath;
